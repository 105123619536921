import React, { useState } from "react"
import Intro from "../components/Intro"
import Info from "../components/Info"
import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"

import Stats from "../components/Stats"
import Explain from "../components/Explain"
import WhatWeDo from "../components/WhatWeDo"
import Scroll from "../components/Scroll"
// import Layout from "../components/layout"
import SEO from "../components/seo"
import { ExplainDataOne, ExplainDataTwo } from "../data/ExplainData"
import InfoSection0 from '../components/InfoSections/InfoSection0'
import { FormattedMessage } from 'react-intl'
import { Layout } from '../components/common/Layout'
// import { Container } from '../components/common/Container'
import Head from '../components/common/Head'
import { homeObjOne, homeObjTwo } from "../components/InfoData/Data"


// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" />
//     <Intro />
//     <Info />
//     {/* <Projects heading="Recent Projects" /> */}
//     <Shippings />
//     <Stats />
//     <Explain {...ExplainDataOne} />
//     <Explain {...ExplainDataTwo} />
//     <WhatWeDo />
//   </Layout>
// )

const IndexPage = () => {

  // const [isOpen, setIsOpen] = useState(false)

  // const toggle = () => {
  //   setIsOpen(!isOpen)
  // }

  return (

    <Layout>
      <>
        <Scroll showBelow={250} />
        <SEO title="Etihad Shipping Germany GmbH" />
        <Head title="HomePageTitle" />
        <Intro />
        {/* <Sidebar isOpen={isOpen} toggle={toggle}/>
          <Navbar toggle={toggle}/> */}
        <InfoSection0 {...homeObjOne} />
        <Info />
        {/* <Projects heading="Recent Projects" /> */}
        <WhatWeDo {...homeObjTwo} />
        <Stats />
      </>
    </Layout>
  )
}


export default IndexPage
