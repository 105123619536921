import ImageOne from '../../assets/images/t1.webp'
import ImageTwo from '../../assets/images/t2.webp'
import ImageThree from '../../assets/images/t3.webp'
import ImageFour from '../../assets/images/t4.webp'
import ImageFive from '../../assets/images/t5.webp'
import ImageSix from '../../assets/images/t6.webp'
import ImageSeven from '../../assets/images/t7.webp'


export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Service',
    headline: 'This is what makes us special.',
    description: 'We offer our customers flexibility in every respect and cater for every individual wish. We aim to provide a tailor-made solution for every order, guaranteeing the highest quality at a fair price. As an established worldwide transporter of vehicles of all kinds.',
    imgStart: false,
    img: ImageOne,
    alt: 'Car',
    dark: true,
    primary: true
}

export const homeObjTwo = {
    id: 'whatwedo',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Service',
    headline: 'This is what makes us special.',
    description: 'We offer our customers flexibility in every respect and cater for every individual wish. We aim to provide a tailor-made solution for every order, guaranteeing the highest quality at a fair price. As an established worldwide transporter of vehicles of all kinds.',
    imgStart: false,
    img: ImageTwo,
    alt: 'Car',
    dark: true,
    primary: true
}

export const aboutObjOne = {
    id: 'about1',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'forwarding company in hamburg',
    headline: 'worldwide shipping of all kinds of vehicles',
    description: 'As an independent agent for shipping, we are not tied to a shipping company and can always offer you the fastest possible loading of your goods.',
    imgStart: false,
    img: ImageThree,
    alt: 'Car',
    dark: true,
    primary: true
}

export const aboutObjTwo = {
    id: 'about2',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Professional Team',
    headline: 'Fully dedicated to shipping',
    description: 'We offer our customers flexibility in every respect and cater for every individual wish. We aim to provide a tailor-made solution for every order, guaranteeing the highest quality at a fair price. As an established worldwide transporter of vehicles of all kinds.',
    imgStart: true,
    img: ImageOne,
    alt: 'Car',
    dark: false,
    primary: false
}

export const aboutObjThree = {
    id: 'about3',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'forwarding company in hamburg',
    headline: 'worldwide shipping of all kinds of vehicles',
    description: 'As an independent agent for shipping, we are not tied to a shipping company and can always offer you the fastest possible loading of your goods.',
    imgStart: false,
    img: ImageFive,
    alt: 'Car',
    dark: true,
    primary: true
}

export const servicesObjOne = {
    id: 'services1',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Premium Services',
    headline: 'We offer professional services',
    description: 'We offer our customers flexibility in every respect and cater for every individual wish. We aim to provide a tailor-made solution for every order, guaranteeing the highest quality at a fair price. As an established worldwide transporter of vehicles of all kinds.',
    imgStart: true,
    img: ImageSix,
    alt: 'Car',
    dark: true,
    primary: true
}

export const projectsObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Service',
    headline: 'This is what makes us special.',
    description: 'We offer our customers flexibility in every respect and cater for every individual wish. We aim to provide a tailor-made solution for every order, guaranteeing the highest quality at a fair price. As an established worldwide transporter of vehicles of all kinds.',
    imgStart: false,
    img: ImageSeven,
    alt: 'Car',
    dark: true,
    primary: true
}