import React, {useEffect} from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import project4 from '../assets/images/project4.jpg'
import { Link } from 'gatsby'
import { FiCheckCircle } from "react-icons/fi"
import Aos from 'aos'
import 'aos/dist/aos.css'





const WhatWeDo = ({ lightBg, id, imgStart, topLine, lightText, lightTextDesc, headline, description, img, alt }) => {
    
  useEffect( () => {
    Aos.init({})
  }, [])
  
  return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine data-aos="fade-up"
                                    data-aos-delay="50"
                                    data-aos-duration="1000"> <FormattedMessage id="MainTop3" /> </TopLine>

                                <Heading lightText={lightText}
                                    data-aos="fade-up"
                                    data-aos-delay="150"
                                    data-aos-duration="1000"> <FormattedMessage id="MainHead3" /> </Heading>

                                <Subtitle lightTextDesc={lightTextDesc}
                                    data-aos="fade-up"
                                    data-aos-delay="250"
                                    data-aos-duration="1000"> <FormattedMessage id="MainDesc3" />

                                </Subtitle>

                                <Features data-aos="fade-up"
                                    data-aos-delay="350"
                                    data-aos-duration="1000">

                                    <Feature> <CheckCircle /> <FormattedMessage id="MainServices1" /> </Feature>
                                    <Feature> <CheckCircle /> <FormattedMessage id="MainServices2" /> </Feature>
                                    <Feature> <CheckCircle /> <FormattedMessage id="MainServices3" /> </Feature>
                                </Features>

                                <BtnWrap data-aos="fade-up"
                                    data-aos-delay="350"
                                    data-aos-duration="1000">

                                    <Btn round small primary to='/services'> <FormattedMessage id="MainBtn3" /> </Btn>
                                    {/* <button>hi</button> */}
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={project4} alt={alt} data-aos="fade-up"
                                    data-aos-delay="150"
                                    data-aos-duration="1000" />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )


}


export default WhatWeDo;


const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? '#dbe2e7' : '#283951')};
  padding: 80px 0 120px 0;

  @media screen and (max-width: 400px) {
      padding: 60px 0 60px 0;
  }
`

const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  max-height: 680px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 820px) {
    max-height: 1120px;
  } 

  /* @media screen and (max-width: 520px) {
    height: 900px;
  }  */
`

const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'` )};

  @media screen and (max-width: 820px) {
      grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
  }
`

const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`

const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`

const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 30px;
  padding-bottom: 60px;

`

const TopLine = styled.p`
  color: #bd0b27;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: start;
`

const Heading = styled.h1`
  /* color: #000; */
  background: -webkit-linear-gradient(#eee, #DEB0BD);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
  padding-bottom: 8px;
  max-width: 460px;
  font-size: 48px;
  line-height: 1.25;
  font-weight: 700;
  text-transform: uppercase;
  text-align: start;
  /* color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')}; */
  background: ${({ lightText }) => (lightText ? '-webkit-linear-gradient(#eee, #DEB0BD)' : '-webkit-linear-gradient(#283951, #bd0b27)')};
  -webkit-background-clip: ${({ lightText }) => (lightText ? 'text' : 'text')};
  -webkit-text-fill-color: ${({ lightText }) => (lightText ? 'transparent' : 'transparent')};


  @media screen and (max-width: 480px) {
      font-size: 32px;
  }
`

const Subtitle = styled.p`
  max-width: 440px;
  /* margin-top: 10px; */
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  text-align: start;
  color: ${({ lightTextDesc }) => (lightTextDesc ? '#f7f8fa' : '#010606')};
`

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`

const ImgWrap = styled.div`
  max-width: 420px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;

`

const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`

const Btn = styled(Link)`
    background: ${({ primary }) => (primary ? '#bd0b27' : '#f8f8f8')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '16px 40px' : '10px 32px')};
    font-size: ${({ big }) => (big ? '20px' : '16px')};
    color: white;
    outline: none;
    border: none;
    text-decoration: none;
    min-width: 100px;
    cursor: pointer;
    transition: 0.3s !important;
    border-radius: ${({ round }) => (round ? '60px' : 'none')};

    &:hover {
        background: ${({ primary }) => (primary ? '#f8f8f8' : '#bd0b27')};
        color: ${({ primary }) => (primary ? '#bd0b27' : 'white')};
    }
    
`

const Features = styled.p`
  max-width: 440px;
  /* margin-top: 10px; */
  margin-bottom: 35px;
  /* font-size: 18px;
  line-height: 24px; */
  text-align: start;
  /* color: ${({ lightTextDesc }) => (lightTextDesc ? '#f7f8fa' : '#010606')}; */
  color: #f7f8fa;
`

const Feature = styled.p`
    margin-bottom: 0.4rem;
    font-size: clamp(0.8rem, 4.8vw, 1.1rem);
`

const CheckCircle = styled(FiCheckCircle)`
    margin-right: 0.5rem;
    color: #bd0b27;
`