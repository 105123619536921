import React, { useEffect } from 'react'
import styled from 'styled-components'
import { BiWorld } from "react-icons/bi"
import { MdTimer } from "react-icons/md"
import { AiOutlineSafety } from "react-icons/ai"
import { FaMoneyCheck } from "react-icons/fa"
import Aos from 'aos'
import 'aos/dist/aos.css'
import { FormattedMessage } from 'react-intl'

const StatsData = [
    {
        icon: (<BiWorld />),
        title: "Over 50 Destinations",
        desc: "We offer you the possibility to load your goods from the busy ports of Europe to over 50 destinations. "
    },
    {
        icon: (<AiOutlineSafety />),
        title: "Safety and Satisfaction",
        desc: "We make sure that your goods stay safe upon shipping and make you satisfied. "
    },
    {
        icon: (<MdTimer />),
        title: "Fast Support and Service",
        desc: "We make sure that your goods stay safe upon shipping and make you satisfied."
    },
    {
        icon: (<FaMoneyCheck />),
        title: "Great Value of Money",
        desc: "We offer you the possibility to load your goods from the busy ports of Europe. "
    }
]

const Stats = () => {

    useEffect( () => {
        Aos.init({})
      }, [])

    return (
        <StatsContainer>
            <StatsHeading data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000"> <FormattedMessage id="StatsHead" />
                    <BottomLine />
                    </StatsHeading>
                    
            <StatsWrapper>

                {/* {StatsData.map((item, index) => {
                    return ( */}
                        {/* <StatsBox key={index} data-aos="fade-up" */}
                        <StatsBox data-aos="fade-up"
                        data-aos-delay="150"
                        data-aos-duration="1000">
                            <Icon> <BiWorld /> </Icon>
                            <Title> <FormattedMessage id="StatsTitle1" /> </Title>
                            <Description> <FormattedMessage id="StatsDesc1" /> </Description>
                        </StatsBox>

                        <StatsBox data-aos="fade-up"
                        data-aos-delay="150"
                        data-aos-duration="1000">
                            <Icon> <AiOutlineSafety /> </Icon>
                            <Title> <FormattedMessage id="StatsTitle2" /> </Title>
                            <Description> <FormattedMessage id="StatsDesc2" /> </Description>
                        </StatsBox>

                        <StatsBox data-aos="fade-up"
                        data-aos-delay="150"
                        data-aos-duration="1000">
                            <Icon> <MdTimer /> </Icon>
                            <Title> <FormattedMessage id="StatsTitle3" /> </Title>
                            <Description> <FormattedMessage id="StatsDesc3" /> </Description>
                        </StatsBox>

                        <StatsBox data-aos="fade-up"
                        data-aos-delay="150"
                        data-aos-duration="1000">
                            <Icon> <FaMoneyCheck /> </Icon>
                            <Title> <FormattedMessage id="StatsTitle4" /> </Title>
                            <Description> <FormattedMessage id="StatsDesc4" /> </Description>
                        </StatsBox>
                    {/* ) */}
                {/* }  */}
                {/* )
                } */}

            </StatsWrapper>
        </StatsContainer>
    )
}

export default Stats

const StatsContainer = styled.div`
    width: 100%;
    background: #dbe2e7;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem calc((100vw - 1300px) / 2)
`

const StatsHeading = styled.h1`
    text-align: center;
    font-size: clamp(1.8rem, 5vw, 2rem);
    margin-bottom: 1.5rem;
    padding: 0 2rem;
    color: #283951;
`

const StatsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    color: #283951;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`

const StatsBox = styled.div`
    height: 100%;
    width: 100%;
    padding: 2rem;
    text-align: center;
`

const Icon = styled.div`
    font-size: 3.8rem;
    margin-bottom: 1rem;
    color: #bd0b27;
    
`

const Title = styled.div`
    font-size: clamp(1.3rem, 2.5vw, 1.7rem);
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: #283951;
`

const Description = styled.p`
    color: #283951;
    
`

const BottomLine = styled.div`
    height: 5px;
    width: 12%;
    display: block;
    background-color: #bd0b27;
    margin: 2rem auto 0 auto;

    
    /* @media screen and (max-width: 1020px) {
            display:none;
    } */
`