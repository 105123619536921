import React, {useEffect} from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import { MdDescription } from 'react-icons/md'
import {IoMdCheckmarkCircleOutline} from 'react-icons/io'
import {RiShip2Line } from 'react-icons/ri'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { FormattedMessage } from 'react-intl'



const Info = () => {

    useEffect( () => {
        Aos.init({})
      }, [])

    const data = useStaticQuery(graphql`
      query {
        allFile(filter: {ext: {regex: "/(jpg)|(png)|(jpeg)/"}, name: {in: ["info-1", "info-2"]}}) {
          edges {
            node {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `)

    return (
        <InfoContainer>
            {/* <ServicesH1
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000">
                Your partner for shipping vehicles all over the world.
            </ServicesH1>
            <BottomLine /> */}
            <Description data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000">
                <FormattedMessage id="InfoHead" />
            {/* <BottomLine /> */}
            </Description>
            <ContentWrapper>
                <ColumnOne>
                <Infos data-aos="fade-up"
                    data-aos-delay="150"
                    data-aos-duration="1000">
                    <h3> <RiShip2Line /> <FormattedMessage id="InfoTitle1" /> </h3>
                    <p> <FormattedMessage id="InfoDesc1" /> </p>
                </Infos>
                <Infos data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="1000">
                    <h3> <IoMdCheckmarkCircleOutline /> <FormattedMessage id="InfoTitle2" /> </h3>
                    <p> <FormattedMessage id="InfoDesc2" /> </p>
                </Infos>
                </ColumnOne>
                <ColumnTwo data-aos="fade-up"
                    data-aos-delay="250"
                    data-aos-duration="1000">
                  {data.allFile.edges.map((image, key) => (
                    <Images key={key} fluid={image.node.childImageSharp.fluid} />
                  ))
                  
                }
                
                </ColumnTwo>
            </ContentWrapper>
        </InfoContainer>
    )
}

export default Info

const InfoContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #dbe2e7;
    color: #283951;
    padding: 2.4rem calc((100vw - 1300px) / 2);
    /* padding: 2.8rem 2.8rem; */
`

const Description = styled.p`
    text-align: start;
    padding: 0 4rem;
    margin-bottom: 1.8rem;
    font-size: clamp(1.8rem, 5vw, 2.2rem);
    font-weight: bold;
    color: #283951;
    width: 90%;
    line-height: 2.2rem;

    @media screen and (max-width: 768px) {
        padding: 0 2.8rem;
    }

`

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 3rem;
    /* margin-top: 2rem; */
    margin-bottom: 2rem;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 768px) {
        padding: 0 2rem;
    }

`

const ColumnOne = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    /* max-width: 520px;
    font-size: clamp(1.2rem, 5vw, 1.3rem);
    max-height: 82%; */
`

const Infos = styled.div`
    padding: 1rem 1rem;
    /* width: 90%; */
    /* padding-right: 3.2rem;
    padding-left: 3.2rem; */
    /* margin-bottom: 1.2rem; */

    h3 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-style: italic;
        /* font-weight: bold; */
        color: #bd0b27;
        text-align: start;
    }

    p {
        color: #283951;
        text-align: start;
    }
`


const ColumnTwo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
    grid-gap: 10px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 1200px) {
        padding: 1rem 2rem;
    }
`

const Images = styled(Img)`
    border-radius: 5px;
    height: 100%;
    width: 100%;
`

const BottomLine = styled.div`
    height: 5px;
    width: 32%;
    display: block;
    background-color: #bd0b27;
    margin: 2rem auto 0 auto;

    
    /* @media screen and (max-width: 768px) {
            display:none;
    } */
`

const ServicesH1 = styled.h1`
  font-size: clamp(1.8rem, 5vw, 2rem);
  margin-bottom: 1.5rem;
  padding: 0 2rem;
  color: #283951;
  width: 60%;
  text-align: center;
  margin: auto;

  @media screen and (max-width: 480px) {
      font-size: 2rem;
  }
`