import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import Video from '../assets/videos/cars.mp4'
import Aos from 'aos'
import 'aos/dist/aos.css'

import { Context } from './common/Context'
import Provider from './common/Layout/Provider'
import { FormattedMessage } from 'react-intl'
import { addLocaleData, IntlProvider } from 'react-intl'


const Intro = () => {

    useEffect( () => {
      Aos.init({})
    }, [])

    return (
        // <IntroContainer>
        //     <IntroBg>
        //         <VideoBg src={Video} type='video/mp4' autoPlay loop muted playsInline/>
        //     </IntroBg>
        //     <IntroContent>
        //         <IntroItems>
        //             <IntroH1>Etihad Shipping Germany GmbH</IntroH1>
        //             <IntroP>Your partner for shipping vehicles and more all over the world.</IntroP>
        //             <Button primary='true' big='true' round='true' to='/contact'>Ship Now</Button>
        //         </IntroItems>
        //     </IntroContent>
        // </IntroContainer>


        <IntroContainer>
            <IntroBg>
                <VideoBg src={Video} type='video/mp4' autoPlay loop muted playsInline/>
            </IntroBg>
            <IntroContent>
                <IntroItems>
                    <IntroH2 data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1200">
                        <FormattedMessage id="IntroH2" />
                    </IntroH2>
                    <IntroH1 
                      data-aos="fade-up"
                      data-aos-delay="350"
                     data-aos-duration="1200"> 
                        <FormattedMessage id="IntroH1" />
                        {/* Leading Company Specialized in Worldwide Shipping of Vehicles */}
                    </IntroH1>
                    <IntroP data-aos="fade-up"
                    data-aos-delay="500"
                    data-aos-duration="1200">
                        <FormattedMessage id="IntroP" />
                    </IntroP>
                </IntroItems>
            </IntroContent>
        </IntroContainer>


    )

}

export default Intro

// const IntroContainer = styled.div`
//     background: #0c0c0c;
//     color: white;
//     padding: 0 1rem;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//     margin-top: -80px;

//     :before {
//         content: '';
//         position: absolute;
//         top: 0;
//         bottom: 0;
//         right: 0;
//         left: 0;
//         z-index: 2;
//         background: linear-gradient(
//             180deg,
//             rgba(0, 0, 0, 0.2) 0%,
//             rgba(0, 0, 0, 0.2) 100%
//         ),
//         linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
//     }
// `


// const IntroBg = styled.div`
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
// `


// const VideoBg = styled.video`
//     width: 100%;
//     height: 100%;
//     -o-obeject-fit: cover;
//     object-fit: cover; 
//     /* filter:brightness(70%);  */
// `


// const IntroContent = styled.div`
//     z-index: 3;
//     height: calc(100vh - 80px);
//     max-height: 100%;
//     padding: 0rem calc((100vw - 1300px) / 2);
   
// `


// const IntroItems = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     height: 100vh;
//     max-height: 100%;
//     padding: 0;
//     color: white;
//     line-height: 1.1;
//     font-weight: bold;
// `


// const IntroH1 = styled.h1`
//     font-size: clamp(1.5rem, 5vw, 3rem);
//     margin-bottom: 4rem;
//     letter-spacing: 1px;
//     padding: 0 1rem;
// `


// const IntroP = styled.p`
//     font-size: clamp(1.1rem, 1vw, 1rem);
//     margin-bottom: 4rem;
//     width: 90%;
//     padding: 0 2rem 0 2rem;
// `



const IntroContainer = styled.div`
    background: #0c0c0c;
    color: white;
    padding: 0 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -80px;

    :before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
        ),
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    }
`


const IntroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`


const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-obeject-fit: cover;
    object-fit: cover; 
    /* transform:rotateY(180deg); */
    /* filter:brightness(70%);  */
`


const IntroContent = styled.div`
    z-index: 3;
    height: calc(100vh - 80px);
    max-height: 100%;
    padding: 0rem calc((100vw - 1300px) / 2);
    /* background-color: red; */
    width: 100%;
   
`


const IntroItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: center;
    height: 100vh;
    max-height: 100%;
    padding: 0;
    color: white;
    line-height: 1.1;
    font-weight: bold;
`


const IntroH2 = styled.h2`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicoder', 'Lucida Grannde', 
    'Lucida Sans', Arial, Helvetica, sans-serif, sans-serif;
    font-size: clamp(0.6rem, 5vw, 2.2rem);
    color: #ded1d1;
    margin-top: 6rem;
    margin-bottom: 2.2rem;
    letter-spacing: 1px;
    line-height: 1.1;
    padding: 0 1rem;
    max-width: 700px;
    text-align: start;
    text-transform: none;
    /* background-color: yellow; */
`

const IntroH1 = styled.h1`
    font-family: 'Trebuchet MS', 'Lucida Sans Unicoder', 'Lucida Grannde', 
    'Lucida Sans', Arial, Helvetica, sans-serif, sans-serif;
    font-size: clamp(2.2rem, 5vw, 3.4rem);
    margin-bottom: 2.2rem;
    letter-spacing: 1px;
    line-height: 1.1;
    padding: 0 1rem;
    max-width: 700px;
    text-align: start;
    /* font-weight: 700; */
    /* text-align: ${({ aln }) => (aln === 'right' ? 'right' : 'left')}; */
`


const IntroP = styled.p`
    font-size: clamp(1.1rem, 1vw, 1rem);
    margin-bottom: 4rem;
    width: 90%;
    padding: 0 1rem;
    text-align: left;
    max-width: 700px;
    line-height: 1.4;
    font-weight: lighter;
    text-align: start;
`





